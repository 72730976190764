import { cn } from '@/lib/utils';
import NextLink from 'next/link';
import {
  CSSProperties,
  ElementType,
  ReactNode,
  useState
} from 'react';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import { getPalette } from 'src/theme/palette';
import typography from 'src/theme/typography';
import { UrlObject } from 'url';

// ----------------------------------------------------------------------

export default function Link({
  href = '',
  target = '_self',
  underline = 'hover',
  inline = true,
  children,
  disabled = false,
  color = null,
  component = 'div', //eslint-disable-line
  onClick = () => null,
  isFunky = false,
  variant = 'body1',
  className = '',
  style = null
}: {
  href?: string | UrlObject;
  target?: '_self' | '_blank' | '_parent' | '_top';
  underline?: 'hover' | 'none';
  inline?: boolean;
  children: ReactNode;
  disabled?: boolean;
  color?: string;
  component?: ElementType<any>; //eslint-disable-line
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isFunky?: boolean;
  variant?: 'body1' | 'body2' | 'subtitle1' | 'subtitle2' | 'caption';
  className?: string;
  style?: CSSProperties;
}) {
  const { themeMode }: SettingsStoreType = useSettingsStore();
  const palette = getPalette({ themeMode });

  let underlineThickness = 1;

  // if variant is one of subtitle1, subtitle2, set the underline thickness to 2
  if (variant === 'subtitle1' || variant === 'subtitle2') {
    underlineThickness = 2;
  }

  // if color is a string such as primary.main, convert it to a hex value by using the theme
  if (color !== null && color.includes('.')) {
    color = palette[color.split('.')[0]][color.split('.')[1]];
  } else if (color === null) {
    color = palette.text.primary;
  }
  const [isHovering, setIsHovering] = useState(false);

  // If variant subtitle1, subtitle2, or body1, set the bottom to -3px
  const bottom =
    variant === 'subtitle1' ||
    variant === 'subtitle2' ||
    variant === 'body1'
      ? '-3px'
      : '0px';

  return (
    <div
      onMouseEnter={() => setIsHovering(disabled ? false : true)}
      onMouseLeave={() => setIsHovering(false)}
      className={cn(
        inline ? 'inline' : '',
        !isFunky ? 'cursor-pointer' : '',
        !isFunky && underline === 'hover' && !disabled
          ? 'hover:before:visible hover:before:w-full'
          : '',
        !isFunky && underline === 'hover'
          ? 'relative before:invisible before:absolute before:bottom-[var(--link-before-bottom)] before:h-[var(--link-before-height)] before:w-0 before:translate-x-0 before:translate-y-0 before:rounded-[var(--link-before-border-radius)] before:bg-[var(--link-before-background)] before:transition-all before:duration-200 before:ease-in-out'
          : '',
        className
      )}
      style={{
        // display: inline ? 'inline' : null,
        ...(!isFunky && {
          // cursor: 'pointer',
          ...(underline === 'hover' && {
            ['--link-before-border-radius' as string]:
              Math.ceil(underlineThickness / 2) + 'px',
            ['--link-before-height' as string]:
              underlineThickness + 'px',
            ['--link-before-bottom' as string]: bottom,
            ['--link-before-background' as string]: `linear-gradient(45deg, ${color}, ${color})`
            // position: 'relative',
            // '&:before': {
            // borderRadius: Math.ceil(underlineThickness / 2) + 'px',
            // content: "''",
            // position: 'absolute',
            // width: '0',
            // height: underlineThickness + 'px',
            // bottom: bottom,
            // transform: 'translate(0%,0%)',
            // background: `linear-gradient(45deg, ${color}, ${color})`,
            // visibility: 'hidden',
            // transition: 'all 0.2s ease-in-out'
            // },
            // ...(!disabled && {
            //   '&:hover:before': {
            //     visibility: 'visible',
            //     width: '100%'
            //   }
            // }),
            // transition: 'all 0.2s ease-in-out'
            // ['--test-color' as string]: 'red',; bg-[var(--test-color)]
          })
        }),
        ...style
      }}
      // component={component}
      onClick={onClick}
    >
      {href ? (
        <NextLink href={href}>
          <a
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              display: 'inline-block',
              transition: 'all 0.2s ease-in-out',
              color: color,
              ...typography?.[isFunky ? 'subtitle2' : variant],
              ...(isFunky && {
                paddingTop: '18px',
                paddingBottom: '18px',
                paddingLeft: '36px',
                paddingRight: '36px',
                border:
                  '2px solid ' +
                  (isHovering
                    ? palette.primary.main
                    : palette.text.primary),
                borderRadius: 8 + 'px',
                color: isHovering
                  ? palette.primary.main
                  : palette.text.primary,
                transform:
                  'translateY(' + (isHovering ? -2 : 0) + 'px)'
              })
            }}
            target={target}
          >
            <p className={variant + (disabled ? ' text-muted' : '')}>
              {children}
            </p>
          </a>
        </NextLink>
      ) : (
        <a
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            transition: 'all 0.2s ease-in-out',
            color: color,
            ...typography?.[isFunky ? 'subtitle2' : variant],
            ...(isFunky && {
              paddingTop: '18px',
              paddingBottom: '18px',
              paddingLeft: '36px',
              paddingRight: '36px',
              border:
                '2px solid ' +
                (isHovering
                  ? palette.primary.main
                  : palette.text.primary),
              borderRadius: 8 + 'px',
              color: isHovering
                ? palette.primary.main
                : palette.text.primary,
              transform: 'translateY(' + (isHovering ? -2 : 0) + 'px)'
            })
          }}
          target={target}
        >
          <p className={variant + (disabled ? ' text-muted' : '')}>
            {children}
          </p>
        </a>
      )}
    </div>
  );
}
